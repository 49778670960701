import React from 'react'
import Image from 'next/legacy/image'
import LinkButton from '../buttons/LinkButton'
import { Text } from '../../elements/Text/Text'
import { styled, theme } from '../../../../stitches.config'
import visaLogo from '/public/images/visa_logo_gradient.png'

const SubHeader = styled('p', {
    fontSize: '$5',
    lineHeight: '1.75rem',
    textAlign: 'left',
    fontWeight: '400',
    letterSpacing: '1px',
    margin: '-1rem 0 2rem 0',
    width: '100%',
    maxWidth: 600,
})
export const Container = styled('div', {
    my: '1rem',
    mx: '7%',
    '@md': {
        ml: '5%',
        mr: '0%',
    },
})

export const ContentGrid = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '@md': {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
})

export const ContentLeft = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignSelf: 'flex-start',
})
export const GradientText = styled('span', {
    linearGradient: `to right, $primary, $secondary`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
})
export const ContentRight = styled('div', {
    maxWidth: '100%',
    '@md': {
        minWidth: '50%',
    },
})

const AbsoluteWrapper = styled('div', {
    position: 'relative',
    mt: '50px',
    backgroundColor: 'rgb(255, 255, 255)',
    '@md': {
        mt: 0,
    },
})

const HandwrittenTextWrapper = styled('div', {
    display: 'flex',
    position: 'absolute',
    zIndex: '1',
    top: '-40px',
    left: '-40px',
    alignItems: 'center',
    flexDirection: 'column',
    transform: 'rotate(-17deg)',
    height: 0,
    '@md': {
        top: '0',
        left: '0',
    },
})

const StyledHandwrittenText = styled(Text, {
    fontSize: '$6',
    textAlign: 'center',
    color: '#3D7CF6',
    lineHeight: '1.7rem',
    letterSpacing: '.04rem',
    maxWidth: '12rem',
})

const StyledLoopedArrowWrapper = styled('div', {
    mt: '20px',
    ml: '40px',
})

const ImageWrapper = styled('div', {
    img: {},
})

const PartnerText = styled(Text, {
    textTransform: 'uppercase',
    color: `${theme.colors.dark}`,
    fontWeight: 600,
    marginRight: '.5rem',
})

export interface IHomepageHeroGreetingCards {
    titleBeforeGradient: string
    gradiantText: string
    titleAfterGradient: string
    gradientColor1: string
    gradientColor2: string
    buttonText: string
    buttonUrl: string
    button2Text: string
    button2Url: string
    nationalBrandsValue: string
    localBrandsValue: string
    accessoryText: string
    image: string
}

export const HomepageHeroGreetingCards = (
    props: IHomepageHeroGreetingCards
) => {
    return (
        <Container>
            <ContentGrid>
                <ContentLeft>
                    <Text
                        as="h1"
                        size={{
                            '@initial': '8',
                            '@md': '9',
                        }}
                        variant="default"
                        css={{
                            fontSize: '2.10rem',
                            textWrap: 'balance',
                            fontWeight: 700,
                            lineHeight: '1.25em',
                            maxWidth: '90%',
                            mb: '2rem',
                            '@md': {
                                maxWidth: '81%',
                                fontSize: '2.65rem',
                                textWrap: 'balance',
                            },
                        }}
                    >
                        {props.titleBeforeGradient}
                        <GradientText> {props.gradiantText} </GradientText>
                        {props.titleAfterGradient}
                    </Text>
                    <SubHeader>
                        Give premium gift cards as unique as they are.
                        Customizable and flexible for any occasion and
                        milestone. Fast shipping.
                    </SubHeader>
                    <LinkButton
                        url={props.buttonUrl}
                        label={props.buttonText}
                        variant={'primary'}
                        size={'md'}
                        textTransform={'uppercase'}
                    />
                    <br />
                    <LinkButton
                        url={props.button2Url}
                        label={props.button2Text}
                        variant={'primaryOutline'}
                        size={'md'}
                        textTransform={'uppercase'}
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '3rem 0 1rem',
                        }}
                    >
                        <PartnerText as={'p'}>Proud partner of:</PartnerText>
                        <Image
                            src={visaLogo}
                            height={'35'}
                            width={'78'}
                            priority={true}
                            alt={'Visa Logo'}
                        />
                    </div>
                </ContentLeft>
                <ContentRight>
                    <AbsoluteWrapper>
                        <HandwrittenTextWrapper>
                            <StyledHandwrittenText
                                as={'p'}
                                variant={'handWritten'}
                            >
                                {props.accessoryText}
                            </StyledHandwrittenText>
                            <StyledLoopedArrowWrapper>
                                <Image
                                    height={'58'}
                                    width={'56'}
                                    src={'/images/arrow_loop.svg'}
                                    priority={true}
                                    alt={'Arrow with loop'}
                                />
                            </StyledLoopedArrowWrapper>
                        </HandwrittenTextWrapper>
                    </AbsoluteWrapper>
                    <ImageWrapper>
                        <Image
                            src={`${props.image}`}
                            alt={'Greeting Card Preview'}
                            width={674}
                            height={613}
                            priority={true}
                        />
                    </ImageWrapper>
                </ContentRight>
            </ContentGrid>
        </Container>
    )
}
